import { createAsyncThunk } from '@reduxjs/toolkit'
import { PartnerApi } from 'src/api/Partner.api'

export const partner_getListPartner = createAsyncThunk(
    '@partner/partner_getListPartner',
    async (params, { rejectWithValue }) => {
        try {
            const response = await PartnerApi.admin_getListPartner(params)
            return response
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)

export const partner_getUserListByPartner = createAsyncThunk(
    '@partner/partner_getUserListByPartner',
    async (params, { rejectWithValue }) => {
        try {
            const response = await PartnerApi.admin_getUserListByPartner(params)
            return response
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }
    }
)
