import { useSelector } from 'react-redux'
import {
    partner_selectState,
    partner_reducerActions,
} from 'src/redux/partner/partner.slice'
import * as partner_thunkActions from 'src/redux/partner/partner.thunk'

export const usePartner = () => {
    const state = useSelector(partner_selectState)

    return {
        ...state,
        ...partner_reducerActions,
        ...partner_thunkActions,
    }
}
